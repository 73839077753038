import React from 'react';
import Layout from '../components/layout';
import { Link } from 'gatsby';
import Footer from '../components/footer';

export default ({ pageContext }) => (
  <Layout>
    <div className='page-body'>
      <section className='hero is-fullheight'>
        <div className='container column is-8'>
          <div className='blog-btn'>
            <Link to='/blog'>Back to Blog</Link>
          </div>
          <h1 dangerouslySetInnerHTML={{ __html: pageContext.title }} />
          <div dangerouslySetInnerHTML={{ __html: pageContext.content }} />
        </div>
      </section>
    </div>
    <Footer />
  </Layout>
);
